@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  body {
    @apply font-poppins antialiased bg-dark text-white;
    letter-spacing: -0.1px;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    @apply font-poppins antialiased;
  }
  :focus-visible {
    @apply outline-none;
  }
}

@layer components {
  /* Sticky Header */
  .js-page-header--is-sticky.page-header--transparent,
  .js-page-header--is-sticky {
    @apply backdrop-blur bg-dark/20 #{!important};
  }
  /* Gradient Shadow*/
  .shadow-gradient {
    position: relative;
    transform: translate(0);
    transform-style: preserve-3d;
  }
  .shadow-gradient:before {
    content: '';
    position: absolute;
    inset: 0;
    transform: translate3d(0, 0, -1px);
    background: linear-gradient(
      96.22deg,
      #150343 -13.02%,
      #b3304a 44.08%,
      #ef4f34 100%
    );
    filter: blur(35px);
  }
  .gradient-button-bg-1 {
    background: linear-gradient(
      98.04deg,
      #2d009e 28.29%,
      #b3304a 64.52%,
      #ef4f34 100%
    );
  }
  .shadow-purple {
    -webkit-box-shadow: 0px -40px 120px 40px rgba(27, 4, 87, 1);
    -moz-box-shadow: 0px -40px 120px 40px rgba(27, 4, 87, 1);
    box-shadow: 0px -40px 120px 40px rgba(27, 4, 87, 1);
  }
  /*** Custom Form ***/
  .input-wrapper {
    @apply w-full rounded-3xl p-px flex;
    background-image: linear-gradient(to right, #150343, #4906d0, #7907ee);
  }
  .input-wrapper input {
    @apply bg-blue;
  }
  /*** Custom Table ***/
  .table-colum-border {
    @apply relative rounded-3xl overflow-hidden;
  }
  .table-colum-border:before {
    @apply absolute inset-0 w-full h-full;
    content: '';
    border-radius: inherit;
    border: 1px solid transparent;
    border-image: linear-gradient(
        96.22deg,
        #150343 -13.02%,
        #4906d0 44.08%,
        #7907ee 100%
      )
      1;
  }
  .table-title {
    background: linear-gradient(
      96.22deg,
      #7907ee -13.02%,
      #8a50ff 44.08%,
      #7907ee 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .table-bg {
    background: linear-gradient(
      96.22deg,
      #150343 -13.02%,
      #4906d0 44.08%,
      #7907ee 100%
    );
  }
  .menu-bg {
    background: linear-gradient(
      180deg,
      rgba(121, 7, 238, 0.4) 0%,
      rgba(179, 48, 74, 0.4) 100%
    );
  }
  .gradient-text-orange {
    background: linear-gradient(180deg, #ef4f34 0%, #812a1c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .wave {
    animation-name: pulse;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    height: 4px;
    y: 11px;
  }

  #wave-1,
  #wave-1-2 {
    animation-delay: 0.2s;
  }

  #wave-2,
  #wave-2-2 {
    animation-delay: 1s;
  }

  #wave-3,
  #wave-3-2 {
    animation-delay: 0.6s;
  }

  #wave-5,
  #wave-5-2 {
    animation-delay: 0.4s;
  }

  #wave-4,
  #wave-4-2 {
    animation-delay: 0.8s;
  }

  @keyframes pulse {
    0%,
    100% {
      height: 4px;
      y: 11px;
    }
    50% {
      height: 26px;
      y: 0px;
    }
  }
  .vertical-center {
    top: 50%;
    transform: translate(0, -50%);
  }
  .total-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .horizontal-center {
    left: 50%;
    transform: translate(-50%, 0);
  }
  .text-balance {
    text-wrap: balance;
  }
}
/* Mobile Menu */
.nav-menu--is-open {
  @apply visible flex flex-col items-start overflow-y-auto px-6 pb-6 pt-0 opacity-100;
}

.nav-open-noscroll {
  @apply h-screen overflow-hidden;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

@import 'tippy.js/dist/tippy.css';
@import 'react-toastify/dist/ReactToastify.css';
@import './cookie.css';
@import './slick-theme.css';

.slick-list .slick-track {
  display: flex;
  align-items: center;
}

// .child-bg::after {
//   content: '';
//   position: absolute;
//   inset: 0;
//   background: linear-gradient(
//     180deg,
//     rgba(121, 7, 238, 0.4) 0%,
//     rgba(179, 48, 74, 0.4) 100%
//   );
// }
