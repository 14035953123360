.gdpr-cookie-notice {
  position: fixed;
  background: #333;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all ease 1s;
  transition: all ease 1s;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 999999999999;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
@media (max-width: 768px) {
  .gdpr-cookie-notice {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.gdpr-cookie-notice-loaded .gdpr-cookie-notice {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.gdpr-cookie-notice-description {
  margin: 0;
  padding: 0 16px 0 0;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}
@media (max-width: 768px) {
  .gdpr-cookie-notice-description {
    padding: 0;
    margin: 0 0 10px 0;
    font-size: 12px;
    line-height: 18px;
  }
}
.gdpr-cookie-notice-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 0 auto;
  background: none;
  width: auto;
  height: auto;
  padding: 0;
}
.gdpr-cookie-notice-nav-item {
  cursor: pointer;
  text-decoration: none;
  display: block;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  color: #fff;
  padding: 0 16px;
  font-size: 15px;
  font-weight: 600;
}
.gdpr-cookie-notice-nav-item:hover {
  text-decoration: none;
}
.gdpr-cookie-notice-nav-item-btn {
  background: #1ad56c;
  border-radius: 3px;
}
.gdpr-cookie-notice-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg height="12" viewBox="0 0 8 12" width="8" xmlns="http://www.w3.org/2000/svg"><path d="m1.41 0-1.41 1.41 4.58 4.59-4.58 4.59 1.41 1.41 6-6z" fill="#1ad56c"/></svg>');
  z-index: 9999999999999;
  background: rgba(0, 0, 0, 0.4);
  overflow: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 10px;
  display: none;
}
.gdpr-cookie-notice-show-modal .gdpr-cookie-notice-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.gdpr-cookie-notice-modal-content {
  width: 470px;
  border-radius: 6px;
  background: #fff;
  margin: auto;
}
.gdpr-cookie-notice-modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #f4f5f7;
}
.gdpr-cookie-notice-modal-title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  padding: 0;
}
.gdpr-cookie-notice-modal-close {
  width: 24px;
  height: 24px;
  margin: 0 0 0 auto;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  position: relative;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.gdpr-cookie-notice-modal-close:before,
.gdpr-cookie-notice-modal-close:after {
  width: 18px;
  height: 2px;
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #bdbdbd;
}
.gdpr-cookie-notice-modal-close:after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}
.gdpr-cookie-notice-modal-close:hover {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 1;
}
.gdpr-cookie-notice-modal-cookies {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.gdpr-cookie-notice-modal-cookie {
  display: block;
  border-bottom: 1px solid #f4f5f7;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.gdpr-cookie-notice-modal-cookie-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.8);
}
.gdpr-cookie-notice-modal-cookie-title {
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  padding: 0;
  line-height: 1;
  position: relative;
}
.gdpr-cookie-notice-modal-cookie-title:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #bdbdbd transparent transparent transparent;
  content: '';
  display: block;
  position: absolute;
  right: -17px;
  top: 50%;
  margin-top: -2px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.open .gdpr-cookie-notice-modal-cookie-title:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.gdpr-cookie-notice-modal-cookie-state {
  color: #1ad56c;
  margin-left: auto;
  font-size: 15px;
}
.gdpr-cookie-notice-modal-cookie-input {
  opacity: 0;
}
.gdpr-cookie-notice-modal-cookie-input:checked + .gdpr-cookie-notice-modal-cookie-input-switch {
  background: #1ad56c;
  -webkit-box-shadow: inset 0px 0px 0px 1px #1ad56c;
  box-shadow: inset 0px 0px 0px 1px #1ad56c;
}
.gdpr-cookie-notice-modal-cookie-input-switch {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -webkit-box-shadow: inset 0px 0px 0px 1px #bdbdbd;
  box-shadow: inset 0px 0px 0px 1px #bdbdbd;
  margin: 0 0 0 auto;
  position: relative;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  cursor: pointer;
}
.gdpr-cookie-notice-modal-cookie-input-switch:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: url('data:image/svg+xml;utf8,<svg height="11" viewBox="0 0 14 11" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m5 10.42-5-5 1.41-1.41 3.59 3.58 7.59-7.59 1.41 1.42z" fill="rgb(255,255,255)" fill-rule="evenodd"/></svg>')
    no-repeat center 7px;
  content: '';
  display: block;
}
.gdpr-cookie-notice-modal-cookie-info {
  margin: 0;
  padding: 0 16px 12px 16px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 16px;
  display: none;
}
.open .gdpr-cookie-notice-modal-cookie-info {
  display: block;
}
.gdpr-cookie-notice-modal-footer {
  background: #f4f5f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 16px 16px 16px;
  border-radius: 0 0 6px 6px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gdpr-cookie-notice-modal-footer-item {
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: #1ad56c;
  position: relative;
}
.gdpr-cookie-notice-modal-footer-item:after {
  position: absolute;
  right: -16px;
  top: 50%;
  margin-top: -5px;
  background: url('data:image/svg+xml;utf8,<svg height="12" viewBox="0 0 8 12" width="8" xmlns="http://www.w3.org/2000/svg"><path d="m1.41 0-1.41 1.41 4.58 4.59-4.58 4.59 1.41 1.41 6-6z" fill="#1ad56c"/></svg>')
    no-repeat left center;
  height: 12px;
  width: 8px;
  content: '';
  display: block;
}
.gdpr-cookie-notice-modal-footer-item-btn {
  cursor: pointer;
  padding: 0 16px;
  color: #fff;
  background: #1ad56c;
  border-radius: 3px;
  position: relative;
}
.gdpr-cookie-notice-modal-footer-item-btn span {
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.gdpr-cookie-notice-modal-footer-item-btn:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  content: '';
  opacity: 0;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  display: block;
  background: url('data:image/svg+xml;utf8,<svg height="11" viewBox="0 0 14 11" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m5 10.42-5-5 1.41-1.41 3.59 3.58 7.59-7.59 1.41 1.42z" fill="rgb(255,255,255)" fill-rule="evenodd"/></svg>')
    no-repeat center;
}
.gdpr-cookie-notice-modal-footer-item-btn.saved span {
  opacity: 0;
}
.gdpr-cookie-notice-modal-footer-item-btn.saved:after {
  opacity: 1;
}
.gdpr-cookie-notice-modal-footer-item:hover {
  text-decoration: none;
  opacity: 1;
}

/*# sourceMappingURL=style.css.map */
